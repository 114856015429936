import React from 'react'
// import { useAppSelector } from '../store/store';
import { styled } from "@mui/material/styles";
import SectionTitle from '../Components/SectionTitle';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';

const PREFIX = "Pricing";
const classes = {
    serviceWrapper: `${PREFIX}-serviceWrapper`,
    imageWhite: `${PREFIX}-imageWhite`,
};
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.serviceWrapper}`]: {
        position: "relative",
        overflow: "hidden",
        padding: theme.spacing(4),
        boxShadow: theme.shadows[2],
        borderRadius: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        // backgroundColor: theme.palette.background.paper,
        [`&:before`]: {
            content: '""',
            width: '275px',
            height: '275px',
            position: 'absolute',
            right: '-137.5px',
            bottom: '-137.5px',
            backgroundColor: theme.palette.primary.light,
            zIndex: '-1',
            borderRadius: '100%',
            WebkitTransition: 'all 1s',
            transition: 'all 1s',
            opacity: "0.1"
        },
        [`&:hover`]: {
            // color: "#fff",
            [`&:before`]: {
                transform: "scale(5)",
                opacity: "0.5"
            },
        }
    },
    [`& .${classes.imageWhite}`]: {
        filter: "brightness(0) invert(1)"
    }
}));

interface inputProps {
    // data: {
    //     id: string,
    //     image: string,
    // }[],
    sectionTitle: string,
    subSectionTitle: string,
    id: string,
}

const Pricing = (props: inputProps) => {
    const { sectionTitle, subSectionTitle, id } = props
    // const websiteData = useAppSelector((state) => state.websiteData).value;

    return (
        <Box py={2} id={id}>
            <SectionTitle
                sectionTitle={sectionTitle}
                subSectionTitle={subSectionTitle}
            />
            <Root style={{ margin: "32px 0", }}>
                <Container maxWidth={'lg'}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                        <Grid container spacing={1} m={0} alignItems={"center"}>
                            <Grid md={6} xs={12}>
                                <Stack>
                                    <Stack alignItems={"center"} spacing={3} width={"100%"}>
                                        <Typography>Weekly</Typography>
                                        <Typography>save $500 every month</Typography>
                                        <Typography>199/w</Typography>
                                    </Stack>
                                    <Divider />
                                    <Stack alignItems={"center"} spacing={3} >
                                        <Typography>Free consulting</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Root>
        </Box>
    )
}

export default Pricing