import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Divider, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Settings from './Settings';
import { styled } from "@mui/material/styles";
import LanguageMenu from './Language';
import DarkModeIcon from './DarkModeIcon';
import { keyframes } from '@mui/system';
import { useAppSelector } from '../store/store';
import useWidth, { isWidthDown } from './helperFunctions/useWidth';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from "react-scroll";

const PREFIX = "Navbar";
const classes = {
    stickyHeader: `${PREFIX}-stickyHeader`,
    animationFade: `${PREFIX}-animationFade`,
    activeLink: `${PREFIX}-activeLink`,
};

const animationFade = keyframes`
    0% {
        top: -50px;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        top: 0;
        -webkit-transform: none;
        transform: none;
    }
`;

const Root = styled(AppBar)(({ theme }) => ({
    background: "transparent",
    borderBottom: `none`,
    boxShadow: "none",
    top: 0,
    [`&.${classes.animationFade}`]: {
        display: "flex !important",
        animation: `${animationFade} 1s both`,
    },
    [`&.${classes.stickyHeader}`]: {
        top: "-50px",
        display: "none",
        background: theme.palette.background.default,
        boxShadow: theme.shadows[5]
    },
    [`& .${classes.activeLink}`]: {
        color: theme.palette.primary.main,
    },
}));

function Navbar() {
    const websiteData = useAppSelector((state) => state.websiteData).value;
    const { t } = useTranslation(["translation", websiteData.app.key])
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [shouldShowHeader, setShouldShowHeader] = useState<boolean>(false);
    const [animationClass, setAnimationClass] = useState<string>('');

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const listenToScroll = () => {
        setShouldShowHeader(window.pageYOffset > 300);
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", listenToScroll);
        };
    }, []);

    useEffect(() => {
        if (shouldShowHeader) {
            setAnimationClass(classes.animationFade);
        } else {
            setAnimationClass('');
        }
    }, [shouldShowHeader]);

    const darkMode = useAppSelector((state) => state.dark).value;

    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("sm", screenWidth);

    return (
        <Root
            position={shouldShowHeader ? "fixed" : "absolute"}
            className={clsx({
                [classes.stickyHeader]: shouldShowHeader,
                [animationClass]: shouldShowHeader,
            })}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
                    <Stack mx={2}>
                        <img src={require(`../assets/images/${websiteData.app.key}/logo.webp`)} alt='logo' height={50} srcSet={require(`../assets/images/${websiteData.app.key}/${darkMode ? 'logoLight.webp' : 'logo.webp'}`)} />
                    </Stack>
                    <Stack direction={"row"} spacing={1} useFlexGap alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"} spacing={1} useFlexGap>
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                {websiteData.homeSections.map((page) => {
                                    if (["Banner", "Counter"].includes(page.component)) {
                                        return ""
                                    }

                                    return (
                                        <HeaderLink
                                            key={page.key}
                                            onClick={handleCloseNavMenu}
                                            sx={{ display: 'block' }}
                                            color='primary'
                                            to={page.key}
                                        >
                                            {t(`${websiteData.app.key}:${page.name}`)}
                                        </HeaderLink>
                                    )
                                })}
                            </Box>
                            {!isScreenSmall && <Stack direction={"row"} spacing={1}>
                                {websiteData.app.key === "mountain" && <Settings />}
                                {websiteData.app.languages.length !== 1 && <LanguageMenu />}
                                <DarkModeIcon />
                            </Stack>}
                        </Stack>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="default"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <Stack p={2}>
                                    <Stack direction={"row"} spacing={1}>
                                        {websiteData.app.key === "mountain" && <Settings />}
                                        {websiteData.app.key === "mountain" && <Divider orientation="vertical" flexItem />}
                                        {websiteData.app.languages.length !== 1 && <LanguageMenu />}
                                        {websiteData.app.languages.length !== 1 && <Divider orientation="vertical" flexItem />}
                                        <DarkModeIcon />
                                    </Stack>

                                    {websiteData.homeSections.map((page) => {
                                        return (
                                            <MenuItem key={page.key} onClick={handleCloseNavMenu}>
                                                <HeaderLink
                                                    key={page.key}
                                                    onClick={handleCloseNavMenu}
                                                    sx={{ display: 'block' }}
                                                    color='primary'
                                                    to={page.key}
                                                >
                                                    {t(`${websiteData.app.key}:${page.name}`)}
                                                </HeaderLink>
                                            </MenuItem>
                                        )
                                    })}
                                </Stack>
                            </Menu>
                        </Box>
                    </Stack>
                </Toolbar>
            </Container>
        </Root>
    );
}

export default Navbar;

const StyledHeaderLink = styled(ScrollLink)(({ theme }) => ({
    display: "inline-block",
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 1.5),
    },
    "&:hover": {
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.action.hover}`,
        width: "100%",
        color: theme.palette.text.secondary,
    },
}));

const HeaderLink = (props: any) => {
    const { onClick, to } = props;
    const theme = useTheme();

    return (
        <StyledHeaderLink
            href='#'
            onClick={onClick}
            activeClass={classes.activeLink}
            spy={true}
            hashSpy={true}
            smooth={true}
            duration={500}
            offset={-theme.mixins.toolbar.minHeight!}
            to={to}
        >
            {props.children}
        </StyledHeaderLink>
    );
};

