import { IconButton, Tooltip } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../store/store';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import { changeDarkMode } from '../store/features/darkMode';

const DarkModeIcon = () => {
    const darkMode = useAppSelector((state) => state.dark);
    const dispatch = useAppDispatch();

    const handleChangeMode = () => {
        localStorage.setItem("darkMode", !darkMode.value ? "dark" : "light")
        dispatch(changeDarkMode(darkMode.value))
    }

    return (
        <Tooltip title={darkMode.value ? t("lightMode") : t("darkMode")}>
            <IconButton onClick={handleChangeMode}>
                {darkMode.value ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>
        </Tooltip>
    )
}

export default DarkModeIcon