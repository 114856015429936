import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

// Define a type for the slice state
interface CounterState {
    value: boolean
}

const isDarkModeEnabled = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

// Define the initial state using that type
const initialState: CounterState = {
    value: localStorage.getItem("darkMode") ? localStorage.getItem("darkMode") === 'dark' ? true : false : isDarkModeEnabled,
}

export const darkModeSlice = createSlice({
    name: 'dark',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        changeDarkMode: (state, action: PayloadAction<boolean>) => {
            state.value = !action.payload
        },
    },
})

export const { changeDarkMode } = darkModeSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.dark.value

export default darkModeSlice.reducer