import { ServicesTypes } from "../../../Sections/data/types"


export const servicesData: ServicesTypes = [
    {
        title: "قسم العمليات الجراحية",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "قسم الطوارئ",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "قسم العيادات الخارجيه",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "قسم الأشعة",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "وحدة فحوصات القلب والشرايين",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "قسم الأسنان",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "وحدة التعقيم المركزي",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "قسم المعمل",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
    {
        title: "قسم العلاج الطبيعي",
        desc: "استقبال طوارئ العظام علي مدار 24 ساعة استقبال طوارئ الجراحة والباطنة علي مدار 24 ساعة",
        icon: ""
    },
]