export const clientsData = [
    {
        "id": "warsha",
        "image": "warsha.jpg"
    },
    {
        "id": "abuZaid",
        "image": "abuZaid.jpg"
    },
    {
        "id": "akmEgypt",
        "image": "akmEgypt.jpg"
    },
    {
        "id": "arabprof",
        "image": "arabprof.jpg"
    },
    {
        "id": "baitward",
        "image": "bait-ward.jpg"
    },
    {
        "id": "elshamy",
        "image": "elshamy.jpg"
    },
    {
        "id": "FayekPhilip",
        "image": "Fayek-Philip.jpg"
    },
    {
        "id": "iCare",
        "image": "iCare.jpg"
    },
    {
        "id": "iSmile",
        "image": "iSmile.jpg"
    },
    {
        "id": "jeep",
        "image": "jeep.jpg"
    },
    {
        "id": "kidsCare",
        "image": "kidsCare.jpg"
    },
    {
        "id": "lasheen",
        "image": "lasheen.jpg"
    },
    {
        "id": "mersaal",
        "image": "mersaal.jpeg"
    },
    {
        "id": "offRoad",
        "image": "offRoad.jpg"
    },
    {
        "id": "smokeArts",
        "image": "smokeArts.jpg"
    },
    {
        "id": "steakBurger",
        "image": "steakBurger.jpg"
    },
    {
        "id": "unaStella",
        "image": "unaStella.jpg"
    }
]