import { Box, Container, Stack, Typography } from '@mui/material'
import SectionTitle from '../Components/SectionTitle';
// import { useTranslation } from 'react-i18next';
// import services1 from "../assets/images/services/services-1.png";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { styled } from "@mui/material/styles";
import clsx from 'clsx';
import { useAppSelector } from '../store/store';
import { ServicesTypes } from './data/types';

const PREFIX = "Services";
const classes = {
    serviceWrapper: `${PREFIX}-serviceWrapper`,
    imageWhite: `${PREFIX}-imageWhite`,
};
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.serviceWrapper}`]: {
        position: "relative",
        overflow: "hidden",
        padding: theme.spacing(4),
        boxShadow: theme.shadows[2],
        borderRadius: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        // backgroundColor: theme.palette.background.paper,
        [`&:before`]: {
            content: '""',
            width: '275px',
            height: '275px',
            position: 'absolute',
            right: '-137.5px',
            bottom: '-137.5px',
            backgroundColor: theme.palette.primary.light,
            zIndex: '-1',
            borderRadius: '100%',
            WebkitTransition: 'all 1s',
            transition: 'all 1s',
            opacity: "0.1"
        },
        [`&:hover`]: {
            // color: "#fff",
            [`&:before`]: {
                transform: "scale(5)",
                opacity: "0.5"
            },
        }
    },
    [`& .${classes.imageWhite}`]: {
        filter: "brightness(0) invert(1)"
    }
}));

interface inputProps {
    data: ServicesTypes,
    sectionTitle: string,
    subSectionTitle: string,
    id: string,
}

const Services = (props: inputProps) => {
    const { data, sectionTitle, subSectionTitle, id } = props

    // const { t } = useTranslation()
    const darkMode = useAppSelector((state) => state.dark).value;
    const websiteData = useAppSelector((state) => state.websiteData).value;

    return (
        <Box py={2} id={id}>
            <SectionTitle
                sectionTitle={sectionTitle}
                subSectionTitle={subSectionTitle}
            />
            <Root style={{ margin: "32px 0", }}>
                <Container maxWidth={'lg'}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                        <Grid container spacing={4} m={0} alignItems={"center"} justifyContent={"center"}>
                            {data.map((service, index) =>
                                <Grid md={3} sm={6} xs={12} key={index}>
                                    <Stack alignItems={"center"} spacing={3} className={classes.serviceWrapper}>
                                        <img src={require(`../assets/images/${websiteData.app.key}/services/services-1.png`)} alt="dd" width={80} className={clsx({ [classes.imageWhite]: darkMode })} />
                                        <Typography>{service.title}</Typography>
                                        <Typography textAlign={"center"}>{service.desc}</Typography>
                                    </Stack>
                                </Grid>
                            )}
                        </Grid>
                    </Stack>
                </Container>
            </Root>
        </Box>
    )
}

export default Services