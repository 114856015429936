import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import configJson from "./websiteData.json"
import { ConfigType } from "./types";

const config = configJson as unknown as ConfigType;

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const hostname = window.location.hostname;
let data
if (config?.[hostname]) {
    data = config?.[hostname]
} else {
    data = config.default
}
const Languages = data.app.languages;
localStorage.setItem("i18nextLng", Languages.length === 1 ? Languages[0] : localStorage.getItem("i18nextLng") ?? Languages[0])

const path = data.app.basePath.length === 1 ? "" : data.app.basePath;

i18n
    .use(Backend)
    .use(LanguageDetector)
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: Languages,
        debug: false,
        backend: {
            loadPath:
                `${path}/locales/{{lng}}/{{ns}}.json?cb=` + new Date().getTime(),
        },
    });

export default i18n;
