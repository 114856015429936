import { TabsDataTypes } from "../../../Sections/data/types"


export const projectsData: TabsDataTypes = {
    tabs: ["ads", "designs", "qrCodes"],
    tabPanels: [
        {
            img: "banner.webp",
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi"
        },
        {
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi"
        },
        {
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi"
        },
    ]
}