import { ServicesTypes } from "../../../Sections/data/types"


export const servicesData: ServicesTypes = [
    {
        title: "Link Building",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
        icon: ""
    },
    {
        title: "Link Building",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
        icon: ""
    },
    {
        title: "Link Building",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
        icon: ""
    },
    {
        title: "Link Building",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
        icon: ""
    },
]