import React from 'react';
import { useAppSelector } from '../../store/store';
import { counterData } from "./data/counter";
import { clientsData } from './data/clients';
import { useTranslation } from 'react-i18next';
import { projectsData } from './data/projects';
import { servicesData } from './data/services';

const Mountain = () => {
    const websiteData = useAppSelector((state) => state.websiteData).value;
    const { t } = useTranslation(["translation", websiteData.app.key]);

    return (
        <>
            {websiteData.homeSections.map((sec) => {
                let Section, data

                try {
                    Section = require(`../../Sections/${sec.component}.tsx`).default;
                } catch (error) {
                    console.error(`Error loading section ${sec}:`, error);
                    // You can return a fallback component or null if the section fails to load
                    return (
                        <div key={sec.component}>
                            <h2>Error loading section: {sec.component}</h2>
                            <p>Could not load the section. Please try again later.</p>
                        </div>
                    );
                }

                if (sec.component === "Counter") {
                    data = counterData;
                } else if (sec.component === "CustomizedTabs") {
                    data = projectsData;
                } else if (sec.component === "Clients") {
                    data = clientsData;
                } else if (sec.component === "Services") {
                    data = servicesData;
                } else {
                    // Handle other sections or default case if necessary
                }

                return <Section
                    key={sec.key}
                    data={data}
                    sectionTitle={t(`${websiteData.app.key}:${sec.name}`)}
                    subSectionTitle={t(`${websiteData.app.key}:${sec.desc}`)}
                    id={sec.key} />;
            })}
        </>
    );
}

export default Mountain;
