import { Box, Container, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import SectionTitle from '../Components/SectionTitle';
// import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/store';

interface inputProps {
    // data: {
    //     id: string,
    //     image: string,
    // }[],
    sectionTitle: string,
    subSectionTitle: string,
    id: string,
}

const About = (props: inputProps) => {
    const { sectionTitle, subSectionTitle, id } = props

    // const { t } = useTranslation()
    const websiteData = useAppSelector((state) => state.websiteData).value;

    return (
        <Box sx={{ background: (theme) => theme.palette.background.paper }} py={2} id={id}>
            <SectionTitle
                sectionTitle={sectionTitle}
                subSectionTitle={subSectionTitle}
            />
            <div style={{ margin: "32px 0", }}>
                <Container maxWidth={'lg'}>
                    <Stack justifyContent={"center"} alignItems={"center"} >
                        <Grid container spacing={4} m={0} alignItems={"center"}>
                            <Grid md={6} xs={12}>
                                <Stack sx={{ border: (theme) => `2px solid ${theme.palette.divider}`, borderRadius: "15px" }} p={0.5} >
                                    <img src={require(`../assets/images/${websiteData.app.key}/banner.webp`)} alt='ss' width={"100%"} style={{ borderRadius: "10px" }} />
                                </Stack>
                            </Grid>
                            <Grid md={6} xs={12}>
                                <Typography variant='body1'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi!
                                    Ullam beatae quae in praesentium hic quam placeat minima itaque rerum fugiat blanditiis cupiditate quis assumenda aperiam explicabo eligendi, optio ad sequi!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </div>
        </Box>
    )
}

export default About