import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import config from "../../websiteData.json";
import { InitWebData, websiteTypes } from '../../types';

// Define a type for the slice state
interface CounterState {
    value: websiteTypes
}

// Define the initial state using that type
const initialState: CounterState = {
    value: new InitWebData(config.default),
}

export const websiteDataSlice = createSlice({
    name: 'websiteData',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        changeWebsiteData: (state, action: PayloadAction<websiteTypes>) => {
            state.value = action.payload
        },
    },
})

export const { changeWebsiteData } = websiteDataSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectWebsiteData = (state: RootState) => state.websiteData.value

export default websiteDataSlice.reducer