import './App.css';
import Navbar from './Components/Navbar';
import withRoot from './HOC/withRoot';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from './store/store';
import getWebsiteData from './HOC/getWebsiteData';
import Footer from './Components/Footer';

function App() {
  const { i18n } = useTranslation();
  const websiteData = useAppSelector((state) => state.websiteData).value;
  document.getElementsByTagName("html")[0].setAttribute("dir", i18n.dir());

  const Data = require(`./Clients/${websiteData.app.key}/index.tsx`).default;


  return (
    <div>
      <Navbar />
      <Data />
      <Footer />
      {/* <a href="https://wa.me/+201157143609/?text=urlencodedtext" target='_blank' rel="noreferrer">sdfsd</a> */}
    </div>
  );
}

export default getWebsiteData(withRoot(App));