export const counterData = [
    {
        "key": "clients",
        "count": "18"
    },
    {
        "key": "projects",
        "count": "10"
    },
    {
        "key": "designs",
        "count": "50"
    },
    {
        "key": "ads",
        "count": "30"
    }
]
