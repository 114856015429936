import { Box, Button, Container, Stack } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import SectionTitle from '../Components/SectionTitle';
import { useTranslation } from 'react-i18next';
// import { useAppSelector } from '../store/store';
import { useForm } from 'react-hook-form';
import ControlMUITextField from '../Components/MUI/TextFieldControl';
import contactUsImage from "../assets/images/contact.png";
import contactImageDark from "../assets/images/contactDark.png";
import { styled } from "@mui/material/styles";
import { useAppSelector } from '../store/store';
import { isMobile } from 'react-device-detect';

const PREFIX = "Contact";

const classes = {
    bannerBack: `${PREFIX}-bannerBack`,
    content: `${PREFIX}-content`,
};

const Root = styled(Box)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    [`& .${classes.content}`]: {
        paddingTop: theme.spacing(10),
        background: theme.palette.divider,
    },
    [`& .${classes.bannerBack}`]: {
        [`& svg`]: {
            [`& path`]: {
                fill: theme.palette.divider,
            },
        },
    },
}));

interface inputProps {
    // data: {
    //     id: string,
    //     image: string,
    // }[],
    sectionTitle: string,
    subSectionTitle: string,
    id: string,
}


const Contact = (props: inputProps) => {
    const { sectionTitle, subSectionTitle, id } = props

    const { t } = useTranslation()
    // const websiteData = useAppSelector((state) => state.websiteData).value;
    const { control, handleSubmit } = useForm()
    const darkMode = useAppSelector((state) => state.dark).value;

    const onSubmit = (data: any) => {
        console.log(data);
    }

    return (
        <Stack sx={{ background: (theme) => theme.palette.background.paper }}>
            <Root
                id={id} sx={{
                    background: `url(${darkMode ? contactImageDark : contactUsImage}) no-repeat top / cover`,
                    backgroundAttachment: isMobile ? "inherit" : "fixed",
                }}
                py={2}
            >
                <SectionTitle
                    sectionTitle={sectionTitle}
                    subSectionTitle={subSectionTitle}
                />
                <div style={{ margin: "32px 0", }}>
                    <Container maxWidth={'sm'}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} m={0} alignItems={"center"} justifyContent={"center"} >
                                <Grid md={12} xs={12}>
                                    <ControlMUITextField
                                        name="fullName"
                                        label={t("fullName")}
                                        required
                                        fullWidth
                                        control={control}
                                    />
                                </Grid>
                                <Grid md={12} xs={12}>
                                    <ControlMUITextField
                                        name="email"
                                        label={t("email")}
                                        required
                                        fullWidth
                                        control={control}
                                    />
                                </Grid>
                                <Grid md={12} xs={12}>
                                    <ControlMUITextField
                                        name="mobile"
                                        label={t("mobile")}
                                        required
                                        fullWidth
                                        control={control}
                                    />
                                </Grid>
                                <Grid md={12} xs={12}>
                                    <ControlMUITextField
                                        name="message"
                                        label={t("message")}
                                        control={control}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid md={12} xs={12}>
                                    <Button type='submit' variant='contained' fullWidth>Send</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                </div>
            </Root>
        </Stack>
    )
}

export default Contact