import { Tune } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { useState } from 'react';
import { useAppDispatch } from '../store/store';
import { changeColor } from '../store/features/color';
import { useTranslation } from 'react-i18next';
import { RgbaColorPicker } from 'react-colorful';

function rgbaToHex(r: number, g: number, b: number, a: number) {
    // Convert the alpha value to a 2-digit hexadecimal
    const alpha = Math.round(a * 255).toString(16).padStart(2, '0').toUpperCase();

    // Convert the red, green, and blue values to 2-digit hexadecimals
    const red = r.toString(16).padStart(2, '0').toUpperCase();
    const green = g.toString(16).padStart(2, '0').toUpperCase();
    const blue = b.toString(16).padStart(2, '0').toUpperCase();

    // Return the hex string in RGBA format
    return `#${red}${green}${blue}${alpha}`;
}

const Settings = () => {
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState({ r: 200, g: 150, b: 35, a: 0.5 });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        dispatch(changeColor(rgbaToHex(color.r, color.g, color.b, color.a)))
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Settings"}
                </DialogTitle>
                <DialogContent>
                    {/* <MuiColorInput
                        format="hex"
                        value={value}
                        onChange={handleChange}
                    /> */}
                    <RgbaColorPicker color={color} onChange={setColor} />
                    <Typography>selected color: {rgbaToHex(color.r, color.g, color.b, color.a)}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleDone} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title={t("settings")}>
                <IconButton onClick={handleClickOpen}>
                    <Tune />
                </IconButton>
            </Tooltip>
        </>
    )
}

export default Settings