import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SectionTitle from '../Components/SectionTitle';
import { Container, Stack, Typography } from '@mui/material';
import { TabsDataTypes } from './data/types';
import { useAppSelector } from '../store/store';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTabs-flexContainer': {
        justifyContent: "center"
    },
}));

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
        color: theme.palette.text.primary,
    },
    '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.light,
    },
}));

const PREFIX = "Tabs";

const classes = {
    slider: `${PREFIX}-slider`,
    sliderTrack: `${PREFIX}-sliderTrack`,
    slide: `${PREFIX}-slide`,
};


const Root = styled(Box)(({ theme }) => ({
    [`& .${classes.slider}`]: {
        height: "200px",
        margin: "auto",
        position: "relative",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
    },
    [`& .${classes.sliderTrack}`]: {
        display: "flex",

        [`&:hover`]: {
            animationPlayState: "paused"
        },
    },
    [`& .${classes.slide}`]: {
        height: "200px",
        width: "180px",
        display: "flex",
        alignItems: "center",
        padding: "15px",
        [`& img`]: {
            width: "100%",
            borderRadius: "50%"
        }
    }
}));



interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface inputProps {
    data: TabsDataTypes,
    sectionTitle: string,
    subSectionTitle: string,
    id: string,
}

export default function CustomizedTabs(props: inputProps) {
    const { data, sectionTitle, subSectionTitle, id } = props
    const theme = useTheme();
    const websiteData = useAppSelector((state) => state.websiteData).value;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Root id={id} sx={{ background: (theme) => theme.palette.background.default }} py={2}>
            <SectionTitle
                sectionTitle={sectionTitle}
                subSectionTitle={subSectionTitle}
            />
            <Box sx={{ width: '100%', minHeight: "400px" }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                >
                    {data.tabs.map((tab, index) =>
                        <StyledTab label={tab} key={index} />
                    )}
                </StyledTabs>
                <div
                // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                // index={value}
                // onChangeIndex={handleChangeIndex}
                >
                    {data.tabPanels.map((tab, index) =>
                        <TabPanel value={value} key={index} index={index} dir={theme.direction}>
                            <Container maxWidth={'lg'}>
                                <Stack justifyContent={"center"} alignItems={"center"} >
                                    <Grid container spacing={4} m={0} alignItems={"center"}>
                                        {tab.img && <Grid md={tab.desc ? 6 : 12} xs={12}>
                                            {<Stack sx={{ border: (theme) => `2px solid ${theme.palette.divider}`, borderRadius: "15px" }} p={0.5} >
                                                <img src={require(`../assets/images/${websiteData.app.key}/${tab.img}`)} alt='ss' width={"100%"} style={{ borderRadius: "10px" }} />
                                            </Stack>}
                                        </Grid>}
                                        {tab.desc && <Grid md={tab.img ? 6 : 12} xs={12}>
                                            <Typography variant='body1'>
                                                {tab.desc}
                                            </Typography>
                                        </Grid>}
                                    </Grid>
                                </Stack>
                            </Container>
                        </TabPanel>
                    )}
                </div>
            </Box>
        </Root>
    );
}
